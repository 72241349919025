import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

export const IconClose = ({ classProps }: PropsWithClassProps) => {
  return (
    <svg className={classProps?.root || 'size-4'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L15.024 15.024M15.024 1L1 15.024" stroke="currentColor" />
    </svg>
  );
};
